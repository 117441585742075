import dayjs from 'dayjs';
import { useQuery } from '@tanstack/react-query';
import useUserState from '../../../../../../services/angular/angularUserState';
import { api } from '../../../../../../services/angular/axios';

import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Filler,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import WhiteContainer from '../../../../../../components/WhiteContainer/WhiteContainer';
import { FormHeader } from '../../../../../../components/FormElements';
import { useParams } from 'react-router-dom';

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Filler
);

export const options = {
	responsive: true,
	plugins: {
		legend: {
			display: false,
		},
	},
};

const IndicatorCharts = () => {
	const company = useUserState((state) => state.company);
	const { id } = useParams();
	const companyId = id ?? company?.id;

	const getDayRange = (numDays: number) => {
		const dayEnd = dayjs().subtract(numDays, 'day').endOf('day'); // Define o final do dia anterior ao atual
		const dayStart = dayEnd.startOf('day'); // Subtrai o número de dias e ajusta para o início do dia
	
		return {
			initDay: dayStart.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
			endDay: dayEnd.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
		};
	};

	const currentDay = getDayRange(0);
	const lastDay = getDayRange(1);
	const twoDaysAgo = getDayRange(2);
	const threeDaysAgo = getDayRange(3);
	const fourDaysAgo = getDayRange(4);
	const fiveDaysAgo = getDayRange(5);
	const sixDaysAgo = getDayRange(6);

	const { data: thisDay } = useQuery(
		['dashboard', companyId, currentDay],
		async () =>
			await api.get(
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				`/company/${companyId}/indicators?initialDate=${currentDay.initDay}&endDate=${currentDay.endDay}&geofenceId=&categories=`
			)
	);

	const { data: prevDay } = useQuery(
		['dashboard', companyId, lastDay],
		async () =>
			await api.get(
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				`/company/${companyId}/indicators?initialDate=${lastDay.initDay}&endDate=${lastDay.endDay}&geofenceId=&categories=`
			)
	);

	const { data: twoDays } = useQuery(
		['dashboard', companyId, twoDaysAgo],
		async () =>
			await api.get(
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				`/company/${companyId}/indicators?initialDate=${twoDaysAgo.initDay}&endDate=${twoDaysAgo.endDay}&geofenceId=&categories=`
			)
	);

	const { data: threeDays } = useQuery(
		['dashboard', companyId, threeDaysAgo],
		async () =>
			await api.get(
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				`/company/${companyId}/indicators?initialDate=${threeDaysAgo.initDay}&endDate=${threeDaysAgo.endDay}&geofenceId=&categories=`
			)
	);

	const { data: fourDays } = useQuery(
		['dashboard', companyId, fourDaysAgo],
		async () =>
			await api.get(
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				`/company/${companyId}/indicators?initialDate=${fourDaysAgo.initDay}&endDate=${fourDaysAgo.endDay}&geofenceId=&categories=`
			)
	);

	const { data: fiveDays } = useQuery(
		['dashboard', companyId, fiveDaysAgo],
		async () =>
			await api.get(
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				`/company/${companyId}/indicators?initialDate=${fiveDaysAgo.initDay}&endDate=${fiveDaysAgo.endDay}&geofenceId=&categories=`
			)
	);
	const { data: sixDays } = useQuery(
		['dashboard', companyId, fiveDaysAgo],
		async () =>
			await api.get(
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				`/company/${companyId}/indicators?initialDate=${sixDaysAgo.initDay}&endDate=${sixDaysAgo.endDay}&geofenceId=&categories=`
			)
	);
	const labels = [
		dayjs().subtract(6, 'day').format('MMM DD'),
		dayjs().subtract(5, 'day').format('MMM DD'),
		dayjs().subtract(4, 'day').format('MMM DD'),
		dayjs().subtract(3, 'day').format('MMM DD'),
		dayjs().subtract(2, 'day').format('MMM DD'),
		dayjs().subtract(1, 'day').format('MMM DD'),
		dayjs().format('MMM DD'),
	];

	return (
<div className="flex flex-col items-center justify-between gap-4 md:flex-row">
			<WhiteContainer>
				<FormHeader title="Performance da Beebee" description="Pedidos de fretes atendidos" />
				<Line
					data={{
						labels,
						datasets: [
							{
								fill: true,
								label: 'Consumo',
								data: [
									sixDays?.data?.operationalPerformance,
									fiveDays?.data?.operationalPerformance,
									fourDays?.data?.operationalPerformance,
									threeDays?.data?.operationalPerformance,
									twoDays?.data?.operationalPerformance,
									prevDay?.data?.operationalPerformance,
									thisDay?.data?.operationalPerformance,
								],
								borderColor: '#FCF366',
								backgroundColor: '#FCF3664d',
							},
						],
					}}
				/>
			</WhiteContainer>
			<WhiteContainer>
				<FormHeader
					title="Ticket médio das entregas"
					description="Custo médio por entrega"
				/>
				<Line
					data={{
						labels,
						datasets: [
							{
								fill: true,
								data: [
									sixDays?.data?.avgTicket,
									fiveDays?.data?.avgTicket,
									fourDays?.data?.avgTicket,
									threeDays?.data?.avgTicket,
									twoDays?.data?.avgTicket,
									prevDay?.data?.avgTicket,
									thisDay?.data?.avgTicket,
								],
								borderColor: '#04ca2f',
								backgroundColor: '#04ca2f4d',
							},
						],
					}}
				/>
			</WhiteContainer>
			<WhiteContainer>
				<FormHeader
					title="Performance das entregas"
					description="Percentual das entregas com sucesso"
				/>
				<Line
					data={{
						labels,
						datasets: [
							{
								fill: true,
								data: [
									sixDays?.data?.performance,
									fiveDays?.data?.performance,
									fourDays?.data?.performance,
									threeDays?.data?.performance,
									twoDays?.data?.performance,
									prevDay?.data?.performance,
									thisDay?.data?.performance,
								],
								borderColor: 'blue',
								backgroundColor: '#1671E44d',
							},
						],
					}}
				/>
			</WhiteContainer>
		</div>
	);
};

export default IndicatorCharts;
