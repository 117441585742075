/* eslint-disable no-undef */
import React, { useRef, useState, useEffect, useMemo, Fragment } from 'react';
import { HiOutlineChevronUpDown } from 'react-icons/hi2';
import { MarkerF } from '@react-google-maps/api';
import { Modal } from '../../../../../../components/Modal';
import {
	Checkbox,
	Form,
	Input,
	Textarea,
	useZodForm,
} from '../../../../../../components/FormElements';
import { api } from '../../../../../../services/angular/axios';
import Button from '../../../../../../components/Button/Button';
import {
	phoneMask,
	removePhoneMask,
} from '../../../../../../helpers/mask/phoneMask';
import { AiOutlineCheck } from 'react-icons/ai';
import { twMerge } from 'tailwind-merge';
import { LocationSchema, LocationSchemaType } from './LocationSchema';
import { RequestFreightForm, locationIndexAtom, updateRouteAtom } from '../..';
import { OrderTypeIcon } from '../../../../../../components/Icon/OrderTypeIcon';
import { useAtom } from 'jotai';
import { useAlert } from '../../../../../../contexts/AlertContext';
import useUserState from '../../../../../../services/angular/angularUserState';
import { GoogleMap } from '../../../../../../components/GoogleMap';
import {
	Prediction,
	SearchResult,
	useLocationState,
} from '../../../../../../store/locationState';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Controller } from 'react-hook-form';
import { Combobox, Transition } from '@headlessui/react';
import { z } from 'zod';
import { BsTrashFill } from 'react-icons/bs';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { ToastError } from '../../../../../../helpers/errors/ToastError';
import { LeafletMap } from '../../../../../../components/Leaflet';
import L, {LeafletEventHandlerFn, LatLng} from "leaflet";
import { Marker } from 'react-leaflet';
import { iconDefault } from '../../../../../../components/Leaflet/icons/customIcon';
import { useNewMaps } from '../../../../../../hooks/useMaps';

interface LocationModalProps {
	isOpen: boolean;
	count: number;
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
	orderForm: RequestFreightForm;
	editForm?: boolean;
	favoritePositionId?: number;
	refetchFavoriteLocation: () => Promise<any>;
}

export const LocationModal = ({
	isOpen,
	setIsOpen,
	orderForm,
	editForm,
	count,
	favoritePositionId,
	refetchFavoriteLocation,
}: LocationModalProps) => {
	const queryClient = useQueryClient();
	const company = useUserState((state) => state.company);
	const userId = useUserState((state) => state.userId);
	const alert = useAlert();
	const [updateDraft, triggerUpdateDraft] = useAtom(updateRouteAtom);
	const [locationIndex] = useAtom(locationIndexAtom);
	const [markerPositionLeaflet, setMarkerPositionLeaflet] =
	useState<L.LatLngExpression>();
	const [markerPosition, setMarkerPosition] =
		useState<google.maps.LatLngLiteral>();
	const [showForm, setShowForm] = useState(false);
	const [center, setCenter] = useState<
		google.maps.LatLng | google.maps.LatLngLiteral | google.maps.LatLngBounds
	>();
	const [centerLeaflet, setCenterLeaflet] = useState<L.LatLngExpression>();
	const [inputValue, setInputValue] = useState('');
	const { results: record, addResult, clearResults } = useLocationState();

	const form = useZodForm({
		schema: LocationSchema,
	});

	const { control, reset } = useZodForm({
		schema: z.object({
			searchResult: z.any().nullable(),
		}),
	});
	const { toggleMaps } = useNewMaps();

	useEffect(() => {
		if (locationIndex !== undefined && isOpen) {
			const locInfo = orderForm.getValues('locations')[locationIndex];

			form.reset({
				...locInfo,
				contact: phoneMask(removePhoneMask(locInfo?.contact || '')),
			});
			setMarkerPosition({
				lat: locInfo?.lat as number,
				lng: locInfo?.lng as number,
			});
			setMarkerPositionLeaflet([locInfo?.lat as number, locInfo?.lng as number]);
		} else {
			form.reset({
				address: '',
				contact: '',
				name: '',
				observations: '',
				responsible: '',
			});
			reset();
			setMarkerPosition(undefined);
			setMarkerPositionLeaflet(undefined);
			setCenter(undefined);
			setCenterLeaflet(undefined);
		}
	}, [locationIndex, isOpen]);

	const { data: predictions, isFetching } = useQuery(
		['predictions', inputValue],
		async () => {
			if (!inputValue) return [];

			api.defaults.baseURL = api.defaults.baseURL?.replace(
				'api/v1',
				'location/v1'
			);

			const { data } = await api.get<Prediction>('predictions', {
				params: {
					query: inputValue,
					country: 'br',
					language: 'pt-BR',
				},
			});

			api.defaults.baseURL = api.defaults.baseURL?.replace(
				'location/v1',
				'api/v1'
			);

			return data.result;
		},
		{
			enabled: !!inputValue,
			onError: (error) => {
				api.defaults.baseURL = api.defaults.baseURL?.replace(
					'location/v1',
					'api/v1'
				);
				ToastError(error);
			},
		}
	);

	const { mutate, data } = useMutation(
		async (selected: SearchResult) => {
			api.defaults.baseURL = api.defaults.baseURL?.replace(
				'api/v1',
				'location/v1'
			);

			const { data } = await api.get('details', {
				params: {
					...selected,
					language: 'pt-BR',
					provider: 'GooglePlacesAutocomplete',
				},
			});

			api.defaults.baseURL = api.defaults.baseURL?.replace(
				'location/v1',
				'api/v1'
			);

			return data;
		},
		{
			onSuccess: (data) => {
				form.setValue('address', data.result.address);
				setInputValue(data.result.address);

				setMarkerPosition({
					lat: data.result.center.lat,
					lng: data.result.center.lng,
				});
				setMarkerPositionLeaflet([data.result.center.lat, data.result.center.lng]);
				setCenter({
					lat: data.result.center.lat,
					lng: data.result.center.lng,
				});
				setCenterLeaflet({lat: data.result.center.lat, lng: data.result.center.lng});
			},
			onError: (error) => {
				api.defaults.baseURL = api.defaults.baseURL?.replace(
					'location/v1',
					'api/v1'
				);
				ToastError(error);
			},
		}
	);

	const timerRef = useRef<any>(null);

	const handleSearch = (value: string) => {
		clearTimeout(timerRef.current);
		timerRef.current = setTimeout(() => {
			setInputValue(value);
		}, 1000);
	};

	const handleMarkerDragEnd: LeafletEventHandlerFn = (event) => {
	
		setMarkerPositionLeaflet(() => ({
			lat: event.target._latlng.lat as number,
			lng: event.target._latlng.lng as number,
		}));
		console.log(markerPositionLeaflet);
		setCenterLeaflet({lat: event.target._latlng.lat as number, lng: event.target._latlng.lng as number});
		// Perform your async action here, for example, fetch data
		const fetchData = async () => {
		  try {
			const geocoder = new google.maps.Geocoder();

			await geocoder.geocode(
				{
					location: {
						lat: event.target._latlng.lat as number,
						lng: event.target._latlng.lng as number,
					},
				},
				(results, status) => {
					if (status === google.maps.GeocoderStatus.OK && results) {
						form.setValue('address', results[0].formatted_address);
					}
				}
			);
		  } catch (error) {
			console.error('Error fetching data:', error);
		  }
		};
		fetchData().catch(error => console.error('Error handling async operation:', error));
	  };

	const DraggableMarker: React.FC = () => {
		const markerRef = useRef<L.Marker>(null);

		useEffect(() => {
		if (markerRef.current) {
			markerRef.current.on('dragend', handleMarkerDragEnd);
		}
		}, []);

		return (
			<Marker
				position={markerPositionLeaflet as LatLng}
				draggable={true}
				ref={markerRef}
				icon={iconDefault()}
		  	/>
		)
	  }


	const handleSubmit = async (data: LocationSchemaType) => {
		if (!markerPosition) return;
		const { favoritePoint, ...rest } = data;

		const locations = orderForm.getValues('locations') || [];

		// locationIndex is used to edit a location
		if (locationIndex !== undefined) {
			const locationToEdit = locations[locationIndex];

			locations.splice(locationIndex, 1, {
				...locationToEdit,
				...rest,
				lat: markerPosition.lat,
				lng: markerPosition.lng,
			});
		} else {
			orderForm.setValue('locations', [
				...locations,
				{
					...rest,
					id: Math.random().toString(36).slice(2, 9),
					lat: markerPosition.lat,
					lng: markerPosition.lng,
					sequence: locations.length,
				},
			]);
		}

		triggerUpdateDraft(!updateDraft);

		setIsOpen(false);
		setShowForm(false);

		if (favoritePoint) {
			if (favoritePositionId) {
				alert.onCustom({
					title: 'Salvar ponto de partida',
					message:
						'Já existe um ponto de partida salvo, deseja substituir por este?',
					confirm: {
						label: 'Sim',
						icon: '',
						onClick: async () => {
							try {
								await api.put(`/favorite-locations/${favoritePositionId}`, {
									...rest,
									lat: markerPosition.lat,
									lng: markerPosition.lng,
									id: favoritePositionId,
									userId,
								});

								void refetchFavoriteLocation();
							} catch (error) {}
						},
					},
					cancel: {
						label: 'Cancelar',
						icon: '',
					},
				});
			} else {
				try {
					await api.post(`/favorite-locations`, {
						...rest,
						lat: markerPosition.lat,
						lng: markerPosition.lng,
						id: favoritePositionId,
						userId,
					});

					void refetchFavoriteLocation();
				} catch (error) {}
			}
		}

		form.reset();
	};
	const handleSubmitLeaflet = async (data: LocationSchemaType) => {
		if (!markerPositionLeaflet) return;
		const { favoritePoint, ...rest } = data;

		const locations = orderForm.getValues('locations') || [];

		// locationIndex is used to edit a location
		if (locationIndex !== undefined) {
			const locationToEdit = locations[locationIndex];

			locations.splice(locationIndex, 1, {
				...locationToEdit,
				...rest,
				lat: markerPositionLeaflet instanceof LatLng ? markerPositionLeaflet.lat : 0,
				lng: markerPositionLeaflet instanceof LatLng ? markerPositionLeaflet.lng : 0,
			});
		} else {
			console.log(markerPositionLeaflet);
			orderForm.setValue('locations', [
				...locations,
				{
					...rest,
					id: Math.random().toString(36).slice(2, 9),
					lat: markerPositionLeaflet instanceof LatLng ? markerPositionLeaflet.lat : 0,
					lng: markerPositionLeaflet instanceof LatLng ? markerPositionLeaflet.lng : 0,
					sequence: locations.length,
				},
			]);
		}

		triggerUpdateDraft(!updateDraft);

		setIsOpen(false);
		setShowForm(false);

		if (favoritePoint) {
			if (favoritePositionId) {
				alert.onCustom({
					title: 'Salvar ponto de partida',
					message:
						'Já existe um ponto de partida salvo, deseja substituir por este?',
					confirm: {
						label: 'Sim',
						icon: '',
						onClick: async () => {
							try {
								await api.put(`/favorite-locations/${favoritePositionId}`, {
									...rest,
									lat: markerPositionLeaflet instanceof LatLng ? markerPositionLeaflet.lat : 0,
									lng: markerPositionLeaflet instanceof LatLng ? markerPositionLeaflet.lng : 0,
									id: favoritePositionId,
									userId,
								});

								void refetchFavoriteLocation();
							} catch (error) {}
						},
					},
					cancel: {
						label: 'Cancelar',
						icon: '',
					},
				});
			} else {
				try {
					await api.post(`/favorite-locations`, {
						...rest,
						lat: markerPositionLeaflet instanceof LatLng ? markerPositionLeaflet.lat : 0,
						lng: markerPositionLeaflet instanceof LatLng ? markerPositionLeaflet.lng : 0,
						id: favoritePositionId,
						userId,
					});

					void refetchFavoriteLocation();
				} catch (error) {}
			}
		}

		form.reset();
	};

	const onDragEnd = async (e: google.maps.MapMouseEvent) => {
		setMarkerPosition(() => ({
			lat: e.latLng?.lat() as number,
			lng: e.latLng?.lng() as number,
		}));

		const geocoder = new google.maps.Geocoder();

		await geocoder.geocode(
			{
				location: {
					lat: e.latLng?.lat() as number,
					lng: e.latLng?.lng() as number,
				},
			},
			(results, status) => {
				if (status === google.maps.GeocoderStatus.OK && results) {
					form.setValue('address', results[0].formatted_address);
				}
			}
		);
	};

	const results = useMemo(
		() => record[`${userId!}-${company!.id}`] || [],
		[company?.id, record[`${userId!}-${company!.id}`]]
	);

	return (
		<Modal
			open={isOpen}
			setOpen={setIsOpen}
			headless
			onClose={() => {
				setShowForm(false);
				setMarkerPosition(undefined);
				setInputValue('');
				form.reset();
			}}
			title="Adicionar Destino"
			className={twMerge(
				'max-w-4xl',
				showForm || editForm ? '' : 'h-auto max-h-screen'
			)}
		>
			<>
				{showForm || editForm ? (
					<Form
						form={form}
						onSubmit={!toggleMaps?.useLeaflet ? handleSubmit : handleSubmitLeaflet}
						className="flex w-full flex-col gap-4 px-0 md:px-20"
					>
						{form.getValues('address') && (
							<div className="flex items-center gap-2 font-semibold text-neutral-0">
								<OrderTypeIcon type="2" />
								{form.getValues('address')}
							</div>
						)}
						<Textarea
							autoFocus
							label="O que o entregador deve fazer?"
							{...form.register('observations')}
							errorMessage={form.formState.errors.observations?.message}
						/>
						<Input
							label="Nome do recebedor ou responsável"
							{...form.register('name')}
							errorMessage={form.formState.errors.name?.message}
						/>
						<Input
							label="Telefone do recebedor"
							{...form.register('contact', {
								onChange: (e) => {
									form.setValue('contact', phoneMask(e.target.value));
								},
							})}
							errorMessage={form.formState.errors.contact?.message}
						/>

						{count === 0 && (
							<Checkbox
								label="Salvar ponto de partida"
								controller={{
									control: form.control,
									name: 'favoritePoint',
								}}
							/>
						)}
						
						<Button
							variant="green"
							className="md:w-fit"
							type="submit"
							disabled={!form.formState.isValid}
						>
							<AiOutlineCheck size={20} />
							Confirmar instruções
						</Button>
					</Form>
				) : (
					<div className="flex h-screen max-h-[calc(100vh-30vh)] w-full flex-col items-center justify-center rounded">
						{form.watch('address') && (
							<>
								<div className="flex items-center gap-2 font-medium text-neutral-0">
									<OrderTypeIcon type="2" />
									{form.watch('address')}
								</div>
								<div className="mt-1 text-sm text-neutral-800">
									Verifique o número do endereço, caso esteja errado, arraste o
									marcador para o local correto.
								</div>
							</>
						)}
						{
						!toggleMaps?.useLeaflet ? (
								<GoogleMap
								center={center}
								options={{
									mapTypeControl: false,
									streetViewControl: false,
									fullscreenControl: false,
									keyboardShortcuts: false,
								}}
								>
								<div className="absolute w-full flex-1 justify-center px-5">
									<Controller
									control={control}
									name="searchResult"
									defaultValue={null}
									render={({ field }) => (
										<Combobox
										as={Fragment}
										value={field.value}
										onChange={(result: SearchResult | "clear") => {
											if (result === "clear") {
											alert.onCustom({
												title: "Limpar histórico",
												message: "Deseja mesmo limpar o histórico de locais?",
												confirm: {
												onClick: () => {
													userId &&
													company?.id &&
													clearResults(`${userId}-${company?.id}`);
			
													setIsOpen(true);
												},
												},
												cancel: {
												onClick: () => {
													setIsOpen(true);
												},
												},
											});
											return;
											}
			
											field.onChange(result);
											mutate(result);
										}}
										nullable
										>
										<div className="relative mt-1 w-11/12">
											<div className="flex justify-center rounded bg-white px-3 py-2 text-sm text-neutral-700 focus:outline-none">
											<Combobox.Input
												className="bg-transparent w-full focus:outline-none"
												onChange={(event) => {
												handleSearch(event.target.value);
												}}
												placeholder="Pesquisar endereço"
												displayValue={(searchResult: SearchResult) =>
												searchResult
													? `${searchResult?.placeName} | ${searchResult?.address}`
													: ""
												}
											/>
											<Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
												<HiOutlineChevronUpDown
												className="h-5 w-5 text-neutral-400"
												aria-hidden="true"
												/>
											</Combobox.Button>
											</div>
											<Transition
											as={Fragment}
											leave="transition ease-in duration-100"
											leaveFrom="opacity-100"
											leaveTo="opacity-0"
											afterLeave={() => {
												queryClient.removeQueries(["search", inputValue]);
											}}
											>
											<Combobox.Options className="absolute z-50 mt-1 max-h-48 min-h-[32px] w-full overflow-y-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
												{isFetching ? (
												<span className="relative select-none py-2 pl-6 pr-4 text-neutral-700">
													Pesquisando...
												</span>
												) : (
												<>
													{(predictions || results)?.map(
													(searchResult, index) => (
														<Combobox.Option
														key={index}
														value={searchResult}
														className={({ active }) =>
															twMerge(
															"relative cursor-pointer select-none py-2 pl-6 pr-4",
															searchResult.historic && 'bg-neutral-50',
															active
																? 'bg-neutral-0 text-white'
																: 'text-neutral-700'
															)
														}
														>
														<div className="flex items-center gap-1">
															<FaMapMarkerAlt />
															<span className="w-max font-medium">
															{searchResult.placeName}
															</span>
															<span className="truncate">
															{searchResult.address}
															</span>
														</div>
														</Combobox.Option>
													)
													)}
													{!!results.length && (
													<Combobox.Option
														value="clear"
														className={({ active }) =>
														twMerge(
															"relative flex cursor-pointer items-center justify-end gap-2 self-end py-2 pl-10 pr-4",
															active
															? "bg-neutral-400 text-white"
															: "text-terracota-500"
														)
														}
													>
														<BsTrashFill />
														Limpar histórico
													</Combobox.Option>
													)}
												</>
												)}
											</Combobox.Options>
											</Transition>
										</div>
										</Combobox>
									)}
									/>
								</div>
			
								{markerPosition && (
									<MarkerF
									position={markerPosition}
									draggable
									// eslint-disable-next-line @typescript-eslint/no-misused-promises
									onDragEnd={async (e) => {
										await onDragEnd(e);
									}}
									onLoad={() => {
										setCenter(markerPosition);
									}}
									/>
								)}
								</GoogleMap>
						) : (
							<>
								<LeafletMap center={centerLeaflet} zoom={markerPositionLeaflet ? 17 : 12} layers={toggleMaps.layers}>
								{markerPositionLeaflet && (
									<DraggableMarker></DraggableMarker>
								)}
								</LeafletMap>
								<div className={`absolute top-8 w-full flex-2 justify-center px-5`}>
									<Controller
									control={control}
									name="searchResult"
									defaultValue={null}
									render={({ field }) => (
										<Combobox
										as={Fragment}
										value={field.value}
										onChange={(result: SearchResult | "clear") => {
											if (result === "clear") {
											alert.onCustom({
												title: "Limpar histórico",
												message: "Deseja mesmo limpar o histórico de locais?",
												confirm: {
												onClick: () => {
													userId &&
													company?.id &&
													clearResults(`${userId}-${company?.id}`);
					
													setIsOpen(true);
												},
												},
												cancel: {
												onClick: () => {
													setIsOpen(true);
												},
												},
											});
											return;
											}
					
											field.onChange(result);
											mutate(result);
										}}
										nullable
										>
										<div className={`absolute pl-20 ${markerPositionLeaflet ? 'mt-20' : ""} w-10/12`}>
											<div className="flex justify-center rounded bg-white px-3 py-2 text-sm text-neutral-700 focus:outline-none">
											<Combobox.Input
												className="bg-transparent w-full focus:outline-none"
												onChange={(event) => {
												handleSearch(event.target.value);
												}}
												placeholder="Pesquisar endereço"
												displayValue={(searchResult: SearchResult) =>
												searchResult
													? `${searchResult?.placeName} | ${searchResult?.address}`
													: ""
												}
											/>
											<Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
												<HiOutlineChevronUpDown
												className="h-5 w-5 text-neutral-400"
												aria-hidden="true"
												/>
											</Combobox.Button>
											</div>
											<Transition
											as={Fragment}
											leave="transition ease-in duration-100"
											leaveFrom="opacity-100"
											leaveTo="opacity-0"
											afterLeave={() => {
												queryClient.removeQueries(["search", inputValue]);
											}}
											>
											<Combobox.Options className="absolute z-50 mt-1 max-h-48 min-h-[32px] w-full overflow-y-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
												{isFetching ? (
												<span className="relative select-none py-2 pl-6 pr-4 text-neutral-700">
													Pesquisando...
												</span>
												) : (
												<>
													{(predictions || results)?.map(
													(searchResult, index) => (
														<Combobox.Option
														key={index}
														value={searchResult}
														className={({ active }) =>
															twMerge(
															"relative cursor-pointer select-none py-2 pl-6 pr-4",
															searchResult.historic && 'bg-neutral-50',
															active
																? 'bg-neutral-0 text-white'
																: 'text-neutral-700'
															)
														}
														>
														<div className="flex items-center gap-1">
															<FaMapMarkerAlt />
															<span className="w-max font-medium">
															{searchResult.placeName}
															</span>
															<span className="truncate">
															{searchResult.address}
															</span>
														</div>
														</Combobox.Option>
													)
													)}
													{!!results.length && (
													<Combobox.Option
														value="clear"
														className={({ active }) =>
														twMerge(
															"relative flex cursor-pointer items-center justify-end gap-2 self-end py-2 pl-10 pr-4",
															active
															? 'bg-neutral-400 text-white'
															: 'text-terracota-500'
														)
														}
													>
														<BsTrashFill />
														Limpar histórico
													</Combobox.Option>
													)}
												</>
												)}
											</Combobox.Options>
											</Transition>
										</div>
										</Combobox>
									)}
									/>
								</div>
							
							</>
						)}
						{markerPosition && (
							<span className="absolute bottom-8 rounded bg-black">
								<Button
									variant="green"
									className="w-auto"
									type="button"
									onClick={() => {
										setShowForm(true);
										if (userId && company?.id)
											addResult(`${userId}-${company.id}`, {
												...data?.result,
												historic: true,
											});

										void queryClient.removeQueries([
											'predictions',
											userId,
											company?.id,
										]);
									}}
								>
									Confirmar{' '}
									{orderForm.getValues('locations')?.length
										? 'Destino'
										: 'Partida'}
								</Button>
							</span>
						)}
					</div>
				)}
			</>
		</Modal>
	);
};
