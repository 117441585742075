import { useEffect, useState } from 'react';
import { BsCreditCard2Back, BsFillInfoCircleFill } from 'react-icons/bs';
import { VscGraphLine } from 'react-icons/vsc';
import { useQuery } from '@tanstack/react-query';
import {
	Input,
	Date as DateComponent,
	Select,
	useZodForm,
} from '../../../../../components/FormElements';
import { FiltersTab } from '../../../../../components/FiltersTab';
import { PageHeader } from '../../../../../components/PageHeader';
import { Tooltip } from '../../../../../components/Tooltip';
import WhiteContainer from '../../../../../components/WhiteContainer/WhiteContainer';
import { api } from '../../../../../services/angular/axios';
import { useAppState } from '../../../../../store/appState';

import IndicatorCharts from './charts/IndicatorCharts';
import {
	IndicatorsFilterSchema,
	IndicatorsFilterSchemaType,
} from './schema/IndicatorsFilterSchema';
import { FaRegMoneyBillAlt } from 'react-icons/fa';
import { BiTimeFive } from 'react-icons/bi';
import { AiOutlineCheck } from 'react-icons/ai';
import { IoWarningOutline } from 'react-icons/io5';
import { twMerge } from 'tailwind-merge';
import { useParams } from 'react-router-dom';
import Button from '../../../../../components/Button/Button';
import InputMoney from '../../../../../components/InputMoney/inputMoney';
import { formatTime } from '../../../../../helpers/format/formatTime';

const OperationDashboard = () => {
	const [newSlaTime, setNewSlaTime] = useState(0);
	const [newSlaCostPerDelivery, setNewSlaCostPerDelivery] = useState(0);
	const { geofence } = useAppState();
	const [driverOptionsParams, setDriverOptionParams] = useState(undefined);
	const { id } = useParams();

	const defaultValueQuery: IndicatorsFilterSchemaType = {
		initialDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString(),
        endDate: new Date().toISOString(),
	};

	const [query, setQuery] = useState<IndicatorsFilterSchemaType | undefined>(defaultValueQuery);

	const form = useZodForm({
		schema: IndicatorsFilterSchema,
		defaultValues: {
			initialDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString(),
			endDate: new Date().toISOString(),
		},
	});

	const { data } = useQuery(
		['indicators', id, query],
		async () =>
			await api.get(
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				`/company/${id}/indicators`,
				{
					params: {
						...query,
					},
				}
			)
	);

	const { data: slaTime, refetch } = useQuery(
		['slaTime', id, query],
		async () => 
			 await api.get(
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				`/company/${id}/indicators/sla-time?initialDate=${query?.initialDate ?? ""}&endDate=${query?.endDate ?? ""}&geofenceId=${query?.geofenceId ?? ""}&categories=${query?.categories ?? ""}&driverId=${query?.driverId ?? ""}`
			)
	);

	const { refetch: slaPutRefetch } = useQuery(
		['newSlaTimeData', id, query, newSlaTime],
		async () =>
			await api.put(
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				`company/${id}/indicators/sla-time`,
				{
					slaTimeTarget: newSlaTime,
				}
			)
	);

	const { data: slaCostPerDelivery, refetch: newSlaCostPerDeliveryRefetch } =
		useQuery(
			['slaCostPerDelivery', id, query],
			async () =>
				await api.get(
					// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
					`/company/${id}/indicators/sla-cost-per-delivery?initialDate=${query?.initialDate ?? ""}&endDate=${query?.endDate ?? ""}&geofenceId=${query?.geofenceId ?? ""}&categories=${query?.categories ?? ""}&driverId=${query?.driverId ?? ""}`
				)
		);


	const { refetch: slaCostPerDeliveryPutRefetch } = useQuery(
		['newCostPerDeliveryData', id, query],
		async () =>
			await api.put(
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				`company/${id}/indicators/sla-cost-per-delivery`,
				{
					slaCostPerDeliveryTarget: newSlaCostPerDelivery,
				}
			)
	);

	const { data: vehicleCategories, refetch: refetchVehicle } = useQuery(
		['vehicle-categories', id, query],
		async () =>
			await api.get(
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				`/categories`,
				{
					params: {
						geofenceId: form
							.watch('geofenceId')
							?.map((state: { value: number }) => state.value)
							.join(','),
					},
				}
			)
	);

	const { data: driversOptions } = useQuery(
		['drivers-options', id, query, driverOptionsParams],
		async () =>
			await api.get(
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				`/companies/${id}/get-client-company-drivers`,
				{
					params: {
						name: driverOptionsParams,
					},
				}
			)
	);

	useEffect(() => {
		void refetchVehicle();
	}, [form.watch('geofenceId')]);

	return (
		<div>
			<PageHeader title="OperationDashboard" description="Desempenho das entregas" />
			<div className="flex flex-col gap-4">
				<IndicatorCharts />
				<div className="flex flex-col">
					<PageHeader
						title="Filtros"
						description="Mostra o desempenho a partir de uma fltragem específica"
					/>

					<FiltersTab
						form={form}
						clearFilters={() => {
							form.reset({
								initialDate: null,
								endDate: null,
								geofenceId: [],
								categories: [],
								operation: null,
								driverId: null,
							});
							setQuery(undefined);
						}}
						onSubmit={(data) => {
							setQuery(data);
						}}
					>
						<DateComponent
							variant="light"
							className="w-auto"
							control={form.control}
							name="initialDate"
							label="De"
						/>
						<DateComponent
							variant="light"
							className="w-auto"
							control={form.control}
							name="endDate"
							label="Até"
						/>
						<Select
							label="UF"
							variant="light"
							className="w-auto min-w-[192px]"
							controller={{
								control: form.control,
								name: 'geofenceId',
							}}
							isMulti
							options={geofence?.map((state) => ({
								label: state.name,
								value: state.id,
							}))}
						/>
						<Select
							label="Categoria de veículo"
							variant="light"
							className="w-auto min-w-[192px]"
							controller={{
								control: form.control,
								name: 'categories',
							}}
							isMulti
							options={vehicleCategories?.data.map(
								(category: { name: string; id: number }) => ({
									label: category.name,
									value: category.id,
								})
							)}
						/>
						<Select
							label="Motorista"
							variant="light"
							className="w-auto min-w-[192px]"
							controller={{
								control: form.control,
								name: 'driverId',
							}}
							options={driversOptions?.data.map(
								(driver: { name: string; id: number }) => ({
									label: driver.name,
									value: driver.id,
								})
							)}
							onInputChange={(value: any) => {
								setDriverOptionParams(value);
							}}
						/>
						<Select
							label="Operação"
							variant="light"
							className="w-auto min-w-[192px]"
							controller={{
								control: form.control,
								name: 'operation',
							}}
							options={[
								{ label: 'Agendado', value: 'scheduled' },
								{ label: 'Imediato', value: 'immediate' },
							]}
						/>
					</FiltersTab>
					<div>
						<div className="mt-4 flex flex-col gap-4 md:grid md:grid-cols-3">
							<WhiteContainer className="h-full border-r-8 border-neutral-0 px-2 shadow-md">
								<div className="flex items-center gap-4 ">
									<h1 className="flex w-full items-center gap-2 font-bold">
										<BsCreditCard2Back />
										Consumo
									</h1>
									<Tooltip message="Indica ao valor de seus gastos, no período">
										<BsFillInfoCircleFill size={16} />
									</Tooltip>
								</div>

								<span className="text-xl font-bold text-orange">
									{data?.data?.total.toLocaleString('pt-br', {
										style: 'currency',
										currency: 'BRL',
									})}
								</span>
							</WhiteContainer>
							<WhiteContainer className="h-full border-r-8 border-neutral-0 px-2 shadow-md">
								<div className="flex items-center gap-4">
									<h1 className="flex w-full items-center gap-2 font-bold">
										<FaRegMoneyBillAlt />
										Ticket médio das entregas
									</h1>
									<Tooltip message="Indica o custo médio por entrega, no período filtrado">
										<BsFillInfoCircleFill size={16} />
									</Tooltip>
								</div>

								<span className="text-xl font-bold text-green">
									{data?.data?.avgTicket.toLocaleString('pt-br', {
										style: 'currency',
										currency: 'BRL',
									})}
								</span>
							</WhiteContainer>
							<WhiteContainer className="h-full border-r-8 border-neutral-0 px-2 shadow-md">
								<div className="flex items-center gap-4">
									<h1 className="flex w-full items-center gap-2 font-bold">
										<VscGraphLine />
										Performance geral das entregas
									</h1>
									<Tooltip message="Indica o percentual das entregas com sucesso, ou seja, as entregas que não tiveram problemas registrados, no período filtrado">
										<BsFillInfoCircleFill size={16} />
									</Tooltip>
								</div>
								<span
									className={twMerge(
										'text-xl font-bold',
										data?.data?.performance < 70
											? 'text-terracota-500'
											: data?.data?.performance < 85
											? 'text-orange'
											: 'text-green'
									)}
								>
									{data?.data?.performance}%
								</span>
							</WhiteContainer>
							<WhiteContainer className="h-full border-r-8 border-neutral-0 px-2 shadow-md">
								<h1 className="flex w-full items-center gap-2 font-bold">
									<BiTimeFive />
									Tempo médio
								</h1>
								<span className="flex items-center gap-2">
									Aceite:
									<p className="text-xl font-bold">
										{formatTime(data?.data?.avgAcceptTime)}
									</p>
									<Tooltip message="Indica o tempo entre a sua solicitação de frete e o aceite pelo entregador, no período filtrado">
										<BsFillInfoCircleFill size={16} />
									</Tooltip>
								</span>
								<span className="flex items-center gap-2">
									Entregas:{' '}
									<p className="text-xl font-bold">
										{formatTime(data?.data?.avgDeliveryTime)}
									</p>
									<Tooltip message="Indica o tempo entre a coleta da mercadoria e sua entrega no destino, no período filtrado">
										<BsFillInfoCircleFill size={16} />
									</Tooltip>
								</span>
							</WhiteContainer>
							<WhiteContainer className="h-full border-r-8 border-neutral-0 px-2 shadow-md">
							<div className="flex items-center gap-4">
									<h1 className="flex w-full items-center gap-2 font-bold">
										<AiOutlineCheck />
										Qual sua meta de tempo de entrega?
									</h1>
									<Tooltip message="Preencha o campo com o valor da meta almejada. Com base nesse valor, nosso sistema calculará e retornará o percentual de entregas dentro da meta no período filtrado.">
										<BsFillInfoCircleFill size={16} />
									</Tooltip>
								</div>
								<span className="flex flex-col">
									<p className="text-xl font-bold">{slaTime?.data ? `${String(slaTime.data)}%` : ""}</p>
									<div className='flex flex-row gap-4 w-full items-center'>
										<Input
											placeholder="Horas"
											type="number"
											className="w-full"
											onChange={(e) => {
												setNewSlaTime(Number(e.target.value));
											}}
										/>
										<Button variant="primary" onClick={() => {
											slaPutRefetch()
												.then(async () => await refetch())
												.catch(error => {
												// Tratar erro, se necessário
												console.error('Erro ao executar slaPutRefetch ou refetch:', error);
												});
											}}>
											Atualizar
										</Button>
									</div>
								</span>
							</WhiteContainer>
							<WhiteContainer className="h-full border-r-8 border-neutral-0 px-2 shadow-md">
							<div className="flex items-center gap-4">
									<h1 className="flex w-full items-center gap-2 font-bold">
										<AiOutlineCheck />
										Qual sua meta de custo por entrega?
									</h1>
									<Tooltip message="Preencha o campo com o valor da meta almejada. Com base nesse valor, nosso sistema calculará e retornará o percentual de entregas dentro da meta no período filtrado.">
										<BsFillInfoCircleFill size={16} />
									</Tooltip>
								</div>
								<span className="flex flex-col">
									<p className="text-xl font-bold">
										{slaCostPerDelivery?.data ? `${String(slaCostPerDelivery.data)}%` : ""}
									</p>
									<div className='flex flex-row gap-4 w-full items-center'>
										<InputMoney
											type="text"
											className="w-full"
											onChange={(e) => {
												setNewSlaCostPerDelivery(parseFloat(e.target.value))
											}}
											value={newSlaCostPerDelivery}
										/>
										<Button variant="primary" onClick={() => {
											slaCostPerDeliveryPutRefetch()
												.then(async () => await newSlaCostPerDeliveryRefetch())
												.catch(error => {
												// Tratar erro, se necessário
												console.error('Erro ao executar:', error);
												});
										}}>
											Atualizar
										</Button>
									</div>
								</span>
							</WhiteContainer>
							<WhiteContainer className="flex h-full flex-col gap-4 border-r-8 border-neutral-0 px-2 shadow-md">
								<div className="flex items-center gap-4">
									<h1 className="flex w-full items-center gap-2 font-bold">
										<IoWarningOutline />
										Problemas na entrega
									</h1>
									<Tooltip message="Indica as principais causas de problemas e insucessos das entregas, no período filtrado">
										<BsFillInfoCircleFill size={16} />
									</Tooltip>
								</div>

								{data?.data.noDeliveryReasons.map(
									(reason: {
										id: number;
										description: string;
										percent: number;
									}) => (
										<div
											key={reason.id}
											className="flex items-center justify-between gap-4 border-b-[1px] border-neutral-0"
										>
											<span>{reason.description}:</span>
											<span className="text-xl font-bold">
												{reason.percent}%
											</span>
										</div>
									)
								)}
							</WhiteContainer>
							<WhiteContainer className="h-full border-r-8 border-neutral-0 px-2 shadow-md">
								<div className="flex items-center gap-4">
									<h1 className="flex w-full items-center gap-2 font-bold">
										<VscGraphLine />
										Performance da Beebee
									</h1>
									<Tooltip message="Indica a proporção entre o número total de fretes finalizados e o número total de fretes solicitados.">
										<BsFillInfoCircleFill size={16} />
									</Tooltip>
								</div>

								<p className={twMerge(
										'text-xl font-bold',
										data?.data?.operationalPerformance < 70
											? 'text-terracota-500'
											: data?.data?.operationalPerformance < 85
											? 'text-orange'
											: 'text-green'
									)}>
									{data?.data?.operationalPerformance}%
								</p>

								<span className='flex items-center gap-2'>
									Sucessos:
									<p className='text-xl font-bold text-green'>
										{data?.data?.successDeliveries}
									</p>
									<Tooltip message="Indica a quantidade de fretes realizados com sucesso, no período filtrado">
										<BsFillInfoCircleFill size={16} />
									</Tooltip>
								</span>
								<span className="flex items-center gap-2">
									Falhas:{' '}
									<p className='text-xl font-bold text-terracota-500'>
										{data?.data?.failedDeliveries}
									</p>
									<Tooltip message="Indica a quantidade de fretes que tiveram alguma falha ou não foram atendidos, no período filtrado">
										<BsFillInfoCircleFill size={16} />
									</Tooltip>
								</span>
							</WhiteContainer>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OperationDashboard;
